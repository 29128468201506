const REGEX_FOR_URL_PATTERN =
  "^((https?):\\/\\/([\\w]|[:0-9]|[\\/._%])*)|(?:\\{\\{)([\\w.-]+)(?:\\}\\})$";

/**
 * Used for message json schema validations
 * source:  https://gitlab.com/fivn/engineering/messaging/schemas/messaging-api/-/blob/master/schema.yml
 */
export const CONVERSATION_NODE_MESSAGE_JSON_SCHEMA = {
  type: "object",
  // $id: "https://five9.com/messaging/schema-agent",
  // $schema: "https://five9.com/messaging/meta-schema",
  $defs: {
    message: {
      type: ["string", "null"],
      description: "Content of message, optional if children are provided",
      maxLength: 4096
    },
    messageNonEmpty: {
      type: "string",
      description: "Content of message, optional if children are provided",
      minLength: 1
    },
    attributes: {
      type: ["object", "null"],
      // maxSerializedSize: 4096,
      description: "Custom attributes",
      additionalProperties: {
        type: ["string", "number", "integer", "boolean", "null"],
        anyOf: [
          { type: "string" },
          { type: "number" },
          { type: "integer" },
          { type: "boolean" }
        ]
      }
    },
    messageButtons: {
      type: "object",
      description: "Message action button",
      allOf: [
        {
          properties: {
            contentType: {
              type: "string",
              description: "Button type",
              enum: [
                "POSTBACK_BUTTON",
                "LINK_BUTTON",
                "REPLY_BUTTON",
                "LOCATION_REQUEST_BUTTON"
              ]
            }
          },
          required: ["contentType", "object"]
        },
        {
          if: {
            properties: {
              contentType: {
                const: "POSTBACK_BUTTON"
              }
            },
            required: ["contentType"]
          },
          then: {
            additionalProperties: false,
            type: "object",
            properties: {
              contentType: {
                type: "string"
              },
              object: {
                additionalProperties: false,
                type: "object",
                required: ["text", "response"],
                properties: {
                  text: {
                    type: "string",
                    description: "Button text"
                  },
                  response: {
                    type: "string",
                    description: "Postback payload"
                  },
                  attributes: {
                    $ref: "#/$defs/attributes"
                  }
                }
              }
            }
          }
        },
        {
          if: {
            properties: {
              contentType: {
                const: "LINK_BUTTON"
              }
            },
            required: ["contentType"]
          },
          then: {
            additionalProperties: false,
            type: "object",
            properties: {
              contentType: {
                type: "string"
              },
              object: {
                additionalProperties: false,
                type: "object",
                required: ["text", "url"],
                properties: {
                  text: {
                    type: "string",
                    description: "Button text"
                  },
                  url: {
                    type: "string",
                    description:
                      "Link used in the client when the button is clicked"
                  },
                  default: {
                    type: ["boolean", "null"],
                    description:
                      "Indicates whether the button is the default action for a message"
                  },
                  attributes: {
                    $ref: "#/$defs/attributes"
                  }
                }
              }
            }
          }
        },
        {
          if: {
            properties: {
              contentType: {
                const: "REPLY_BUTTON"
              }
            },
            required: ["contentType"]
          },
          then: {
            additionalProperties: false,
            type: "object",
            properties: {
              contentType: {
                type: "string"
              },
              object: {
                additionalProperties: false,
                type: "object",
                required: ["text", "response"],
                properties: {
                  text: {
                    type: "string",
                    description: "Button text"
                  },
                  response: {
                    type: "string",
                    description: "String payload used when reply is posted"
                  },
                  attributes: {
                    $ref: "#/$defs/attributes"
                  },
                  image: {
                    type: ["string", "null"],
                    pattern: REGEX_FOR_URL_PATTERN,
                    description: "Reply option icon"
                  }
                }
              }
            }
          }
        },
        {
          if: {
            properties: {
              contentType: {
                const: "LOCATION_REQUEST_BUTTON"
              }
            },
            required: ["contentType"]
          },
          then: {
            additionalProperties: false,
            type: "object",
            properties: {
              contentType: {
                type: "string"
              },
              object: {
                additionalProperties: false,
                type: "object",
                required: ["text"],
                properties: {
                  text: {
                    type: "string",
                    description: "Button text"
                  },
                  attributes: {
                    $ref: "#/$defs/attributes"
                  }
                }
              }
            }
          }
        }
      ]
    }
  },
  allOf: [
    {
      properties: {
        contentType: {
          type: "string",
          description: "Message type",
          enum: ["TEXT", "IMAGE"]
        }
      },
      required: ["contentType", "object"]
    },
    {
      if: {
        properties: {
          contentType: {
            const: "TEXT"
          }
        },
        required: ["contentType"]
      },
      then: {
        additionalProperties: false,
        type: "object",
        properties: {
          contentType: {
            type: "string"
          },
          object: {
            type: "object",
            $comment:
              "If message is specified, children are optional, otherwise they are required",
            if: {
              required: ["message"],
              properties: {
                message: {
                  $ref: "#/$defs/messageNonEmpty"
                }
              }
            },
            then: {
              additionalProperties: false,
              properties: {
                message: {
                  $ref: "#/$defs/message"
                },
                children: {
                  type: "array",
                  description: "Message action buttons",
                  items: {
                    $ref: "#/$defs/messageButtons"
                  }
                }
              }
            },
            else: {
              additionalProperties: false,
              type: "object",
              required: ["children"],
              properties: {
                message: {
                  $ref: "#/$defs/message"
                },
                children: {
                  type: "array",
                  description: "Message action buttons",
                  minItems: 1,
                  items: {
                    $ref: "#/$defs/messageButtons"
                  }
                }
              }
            }
          }
        }
      }
    },
    {
      if: {
        properties: {
          contentType: {
            const: "IMAGE"
          }
        },
        required: ["contentType"]
      },
      then: {
        additionalProperties: false,
        type: "object",
        properties: {
          contentType: {
            type: "string"
          },
          object: {
            type: "object",
            $comment:
              "If message is specified, children are optional, otherwise they are required",
            if: {
              required: ["message"],
              properties: {
                message: {
                  $ref: "#/$defs/messageNonEmpty"
                }
              }
            },
            then: {
              additionalProperties: false,
              type: "object",
              required: ["image"],
              properties: {
                image: {
                  type: "string",
                  pattern: REGEX_FOR_URL_PATTERN,
                  description: "Image URL"
                },
                title: {
                  type: ["string", "null"],
                  description: "Image title",
                  maxLength: 128
                },
                message: {
                  $ref: "#/$defs/message"
                },
                children: {
                  type: ["array", "null"],
                  description: "Message action buttons",
                  items: {
                    $ref: "#/$defs/messageButtons"
                  }
                }
              }
            },
            else: {
              additionalProperties: false,
              type: "object",
              required: ["image", "children"],
              properties: {
                image: {
                  type: "string",
                  pattern: REGEX_FOR_URL_PATTERN,
                  description: "Image URL"
                },
                title: {
                  type: ["string", "null"],
                  description: "Image title",
                  maxLength: 128
                },
                message: {
                  $ref: "#/$defs/message"
                },
                children: {
                  type: "array",
                  minItems: 1,
                  description: "Message action buttons",
                  items: {
                    $ref: "#/$defs/messageButtons"
                  }
                }
              }
            }
          }
        }
      }
    }
  ]
};
